
export default ()=>{
  const config = useRuntimeConfig().public

  // COMMENT: If job data, which only appears in the Posting API, this endpoint can be used
  const getPosting = async (postingId) => {
    return await useLazyFetch(
      `https://api.smartrecruiters.com/v1/companies/implementconsultinggroup/postings/${postingId}`,
      {
        key: `posting-${postingId}`,
        headers: {
          'X-SmartToken': config.SMARTRECRUITERS_SMART_TOKEN,
          accept: 'application/json',
        },
      }
    )
  }

  const getJob = async (jobId) => {
    return await useFetch(
      `https://api.smartrecruiters.com/jobs/${jobId}`,
      {
        key: `job-${jobId}`,
        headers: {
          'X-SmartToken': config.SMARTRECRUITERS_SMART_TOKEN,
          accept: 'application/json',
        },
        lazy: false, // set to false to retieve seo-image before page-load
      }
    )
  }

  const getApplication = async (url, postingUuid) => {
    return await useLazyFetch(
      url,
      {
        key: `application-${postingUuid}`,
        headers: {
          'X-SmartToken': config.SMARTRECRUITERS_SMART_TOKEN,
          accept: 'application/json',
        }
      }
    )
  }

  const getJobContacts = (jobData) => {
    // COMMENT: Unfortunately endpoint(custom smartrecruiters) data can return list of email, names and initials. In some cases "properties" are undefined, and in others contacts are not populated
    // ONLY CASE HANDLED is comma-separated list of emails(or at least comma-separated data)

    if (!jobData?.properties) return false

    const contacts = jobData?.properties.find(property => property.id === '5e847731200b9d25f9f0bf25') // 5e847731200b9d25f9f0bf25
    if (!contacts?.value?.label) return false

    return contacts?.value?.label
    // Could also return array of people/emails
    // return contacts?.value?.label?.trim().split(',')
  }

  const getJobSeoImage = (jobData) => {
    if (!jobData?.properties) return false

    const image = jobData?.properties.find(property => property.id === '5e84774e6f48d35fe171d632') // 5e847731200b9d25f9f0bf25
    if (!image?.value?.label) return false

    return image?.value?.label
  }

  const getExpertiseArea = (jobData) => {
    if (!jobData?.properties) return false

    const ea = jobData?.properties.find(property => property.id === '61275673e21aaa5f043f630f') // 5e847731200b9d25f9f0bf25
    if (!ea?.value?.label) return false

    return ea?.value?.label
  }

  const getThemeColor = (jobData) => {
    if (!jobData?.properties) return false

    const themeColor = jobData?.properties.find(property => property.id === '5e9c3e5f8cd78a1c4c486bfe') // 5e847731200b9d25f9f0bf25
    if (!themeColor?.value?.label) return false

    return themeColor?.value?.label
  }

  const acceptedMimeTypes = (fileType)=>{
    console.log('file type test: ', fileType)
    switch (fileType) {
      case 'application/pdf':
      case 'application/rtf': // rich-text-format
      case 'application/msword': // .doc
      case 'image/jpeg':
      case 'image/png':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // .docx
        return true
        break
      default:
        return false
        break
    }
  }

  return {
    getPosting,
    getJob,
    getJobContacts,
    getJobSeoImage,
    getExpertiseArea,
    getThemeColor,
    getApplication,
    acceptedMimeTypes,
  }
}

